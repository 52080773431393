import { computed } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { RejectedCPSListModel } from "./RejectedCPSListModel";
import { formatDate } from "Utils/Format";
import { VariationStatusTypeModel } from "Globals/Models/Domain/VariationStatusTypeModel";

export class RejectedCPSListItemViewModel extends ViewModelBase<RejectedCPSListModel> {
    constructor(item: RejectedCPSListModel, statusType: VariationStatusTypeModel) {
        super(item);
        this.statusTypeModel = statusType;
    }

    //private model: RejectedCPSListModel;
    private statusTypeModel: VariationStatusTypeModel;

    @computed
    public get id() {
        return this.model.id;
    }

    @computed
    public get variationNumber() {
        return this.model.variationNumber;
    }

    @computed
    public get formattedVariationNumber() {
        return this.model.formattedVariationNumber;
    }

    @computed
    public get revision() {
        return this.model.revision;
    }

    @computed
    public get description() {
        return this.model.description;
    }

    @computed
    public get lastUpdatedDate() {
        return this.model.lastUpdatedDate;
    }

    @computed
    public get lastUpdatedDateFormatted() {
        return formatDate(this.model.lastUpdatedDate !== null ? this.model.lastUpdatedDate.toString() : "");
    }

    @computed
    public get statusName() {
        return this.statusTypeModel.displayName;
    }

    @computed
    public get statusNameFormatted() {
        return this.statusTypeModel.displayName.toUpperCase();
    }

    @computed
    public get statusColor() {
        return this.statusTypeModel.color;
    }

    @computed
    public get statusTextColor() {
        return this.statusTypeModel.textColor;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public async isFieldValid(fieldName: keyof FieldType<RejectedCPSListModel>): Promise<boolean> {
        return true;
    }
}
