// Libraries
import { isNullOrUndefined, styled, useObserver, useRouter, Grid } from "@shoothill/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import * as Defaults from "Globals/Defaults/TableOptions";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { DarwinPage, DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { VariationListViewModel } from "./VariationListViewModel";
import { VariationStatus, VariationStatusHelpers } from "./VariationStatusEnum";
import DeleteLogo from "Content/Bin.svg";
import { PackagesViewModel } from "../Commercial/PackagesViewModel";
import { VariationTableModel } from "./VariationListModel";
import { AppUrls } from "AppUrls";
import { VARGridItemModel } from "./VARGridItemModel";
import { VARItemModalRequest } from "./VARGridModel";
import { DeleteModal } from "Components/Modal/DeleteModal";
import { ApprovedVariationTableModel } from "./ApprovedVariationListModel";
import { Typography } from "@material-ui/core";
import { formatCreditDebitClass, getCreditDebitBackgroundColor } from "Utils/Utils";
import { formatCurrencyFromPounds } from "Utils/Format";
import { PatchedPagination } from "../../../Components/Table/PatchedPagination";
import { VariationTypeEnum } from "Views/Variation/Form/VariationModel";
import { PrimaryButton } from "Components/Buttons/Buttons";

interface ITableProps {
    ieId: string;
    variationType: VariationTypeEnum;
    variationTypeName: string;
    packagesViewModel: PackagesViewModel;
    chosenId(id: string): void;
    rowToEdit(varmodel: VARGridItemModel, parent: string, packageItem: VARItemModalRequest): void;
    rowTitle(title: string | undefined): void;
}

export const VariationListView: React.FunctionComponent<ITableProps> = (props) => {
    const { ieId } = props;
    const [viewModel] = useState(() => VariationListViewModel.Instance);
    const { history } = useRouter();
    const router = useRouter();
    useEffect(() => {
        viewModel.setVariationType(props.variationType);
        if (isNullOrUndefined(ieId) === false) {
            viewModel.GetVariationList(ieId);
            viewModel.GetApprovedVariationsList(ieId);
        }
    }, [props.variationType]);

    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const handleRowClick = (e?: React.MouseEvent<Element, MouseEvent> | undefined, rowData?: VariationTableModel) => {
        e?.stopPropagation();

        if (rowData) {
            if (props.variationType === VariationTypeEnum.Variation) {
                history.push(AppUrls.Client.Variation.Edit.replace(":variationid", rowData.id).replace(":approvalMode", "false"));
            } else if (props.variationType === VariationTypeEnum.ClientProvisionalSum) {
                history.push(AppUrls.Client.ClientProvisionalSum.Edit.replace(":variationid", rowData.id).replace(":approvalMode", "false"));
            }
        }
    };

    const handleApprovedRowClick = (e?: React.MouseEvent<Element, MouseEvent> | undefined, rowData?: ApprovedVariationTableModel) => {
        e?.stopPropagation();

        if (rowData) {
            if (props.variationType === VariationTypeEnum.Variation) {
                history.push(AppUrls.Client.Variation.View.replace(":variationid", rowData.id));
            } else if (props.variationType === VariationTypeEnum.ClientProvisionalSum) {
                history.push(AppUrls.Client.ClientProvisionalSum.View.replace(":variationid", rowData.id));
            }
        }
    };

    const handleDeleteVariationClick = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, rowData: VariationTableModel) => {
        if (rowData.canDeleteVariation) {
            e.stopPropagation();
            viewModel.setVariationIdToDelete(rowData.id);
            setDeleteOpen(true);
        }
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        viewModel.setVariationIdToDelete(null);
    };

    const handleDeleteVariation = () => {
        const { ieId } = router.match.params as any;

        if (viewModel.variationIdToDelete) {
            viewModel.deleteVariation(viewModel.variationIdToDelete, ieId);
            viewModel.setVariationIdToDelete(null);
            setDeleteOpen(false);
        }
    };

    const exportCSV = () => {
        viewModel.generateCSV(ieId);
    };

    const renderVariationsTable = () => {
        return (
            <MaterialTable
                columns={[
                    {
                        title: props.variationTypeName,
                        field: "variationNumberFormatted",
                    },
                    {
                        title: "Line total",
                        field: "lineTotal",
                        render: (rowData: VariationTableModel) => <>{rowData.lineTotalFormatted}</>,
                    },
                    {
                        title: "Future spend",
                        field: "futureSpend",
                        render: (rowData: VariationTableModel) => <>{rowData.futureSpendFormatted}</>,
                    },
                    {
                        title: "Variance",
                        field: "variance",
                        render: (rowData: VariationTableModel) => <>{rowData.varianceFormatted}</>,
                    },
                    {
                        title: "Status",
                        field: "status",
                        render: (rowData: VariationTableModel) => (
                            <StatusCell
                                className={VariationStatusHelpers.getClassName(rowData.status as VariationStatus)}
                                style={{
                                    backgroundColor: VariationStatusHelpers.getCellColour(rowData.status as VariationStatus, theme),
                                    width: 275,
                                    color: VariationStatusHelpers.getCellTextColour(rowData.status as VariationStatus),
                                }}
                            >
                                <div>{VariationStatusHelpers.getText(rowData.status as VariationStatus)}</div>
                            </StatusCell>
                        ),
                    },
                    {
                        title: "Client response by",
                        field: "clientResponseBy",
                        render: (rowData: VariationTableModel) => <>{rowData.clientResponseByFormatted}</>,
                    },
                    {
                        title: "",
                        field: "",
                        render: (rowData: VariationTableModel) =>
                            rowData.canDeleteVariation && (
                                <div className="table-svg-container">
                                    <img src={DeleteLogo} onClick={(e) => handleDeleteVariationClick(e, rowData)} />
                                </div>
                            ),
                    },
                ]}
                components={{
                    Pagination: PatchedPagination,
                }}
                options={Defaults.GetDarwinTableOptionsNoSearchV2()}
                data={viewModel.returnVariations}
                onRowClick={handleRowClick}
                title=""
                isLoading={viewModel.IsLoading}
            />
        );
    };

    const renderApprovedVariationsTable = () => {
        return (
            <>
                <br />
                <br />

                <Typography variant="h2" color="textPrimary">
                    Approved {props.variationTypeName}
                </Typography>

                <br />

                <MaterialTable
                    columns={[
                        {
                            title: props.variationTypeName,
                            field: "variationNumberFormatted",
                        },
                        {
                            title: "Income",
                            field: "income",
                        },
                        {
                            title: "Target cost",
                            field: "targetCost",
                        },
                        {
                            title: "Margin %",
                            field: "margin",
                        },
                        {
                            title: "Committed cost",
                            field: "committedCost",
                        },
                        {
                            title: "Future spend",
                            field: "futureSpend",
                        },
                        {
                            title: "Total expected spend",
                            field: "totalExpectedSpend",
                        },
                        {
                            title: "Variance",
                            field: "variance",
                            render: (rowData) => (
                                <div className={formatCreditDebitClass(rowData.variance)}>{rowData.variance ? formatCurrencyFromPounds(rowData.variance) : "-"}</div>
                            ),
                            // cellData parameter required even though it is unused.
                            cellStyle: (cellData, rowData) => {
                                return {
                                    backgroundColor: getCreditDebitBackgroundColor(rowData.variance),
                                    fontFamily: ["Open Sans", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                                    fontSize: pxToRem(12),
                                    letterSpacing: pxToRem(-0.24),
                                    padding: `${pxToRem(6)} ${pxToRem(16)}`,
                                };
                            },
                        },
                        {
                            title: "Risk/opportunity",
                            field: "riskAndOpportunity",
                        },
                    ]}
                    components={{
                        Pagination: PatchedPagination,
                    }}
                    options={Defaults.GetDarwinTableOptionsNoSearchV2()}
                    data={viewModel.returnApprovedVariations}
                    onRowClick={handleApprovedRowClick}
                    title=""
                    isLoading={viewModel.IsLoading}
                />
            </>
        );
    };

    const renderPage = () => {
        return (
            <VariationTablesPage>
                <DarwinPage>
                    <Grid columnGap={30} dc={"1fr 1fr 1fr 1fr 1fr 1fr"} rowGap={15} tc={"1fr 1fr 1fr 1fr 1fr 1fr"}>
                        {viewModel && <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading} />}
                    </Grid>
                    <br />
                    <DarwinTablePageContent>
                        {renderVariationsTable()}
                        {renderApprovedVariationsTable()}
                    </DarwinTablePageContent>
                </DarwinPage>

                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleDeleteVariation}
                    title={`Delete ${props.variationTypeName}?`}
                    text={`Are you sure you want to delete ${props.variationTypeName} ${viewModel.getVariationToDeleteNumber}?`}
                />
            </VariationTablesPage>
        );
    };
    return useObserver(() => renderPage());
};

export const VariationTablesPage: any = styled.div`
    padding: 30px 5px;

    .MuiBox-root {
        padding: 0 !important;
    }
    ul {
        width: 100%;
        margin: 0 0 50px;
    }
    > h1 {
        font-size: 22px;
        color: #000;
        margin: 25px 0 25px;
    }
    table {
        th,
        td {
            text-align: center !important;
        }
        th:first-child,
        td:first-child {
            text-align: left !important;
        }
        th:last-child,
        td:last-child {
            text-align: right !important;
        }
        tbody tr td {
            padding: 0.8rem 1rem !important;
            border-right: 1px solid #ddd;
            &:last-child {
                border-right: none;
            }
        }
    }
`;
