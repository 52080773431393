import { action, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";

export class AllProjectApprovedInvoicesReportModel extends ModelBase<AllProjectApprovedInvoicesReportModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public reportFromDate: string | null = moment().subtract(1, "week").utc().endOf("day").toISOString();

    @observable
    public reportToDate: string | null = moment().endOf("day").utc().toISOString();

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.reportFromDate = moment().subtract(1, "week").utc().endOf("day").toISOString();
        this.reportToDate = moment().endOf("day").utc().toISOString();
    };

    @action
    public fromDto(dto: any): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): void {}

    // #endregion Actions

    // #region Custom Validation

    // #endregion Business Logic
}

export type AllProjectApprovedInvoicesReportRequestDTO = {
    fromDate: string | null;
    toDate: string | null;
};

export type AllProjectApprovedInvoiceReportResponseDTO = {
    approvedInvoices: AllProjectApprovedInvoiceReportAllProjectDTO[];
};

export type AllProjectApprovedInvoiceReportAllProjectDTO = {
    id: string;
    invoiceNumber: string;
    invoiceValueNet: number;
    invoiceValueGross: number;
    invoiceDate: string;
    dueDate: string;
    postedDate: string;
    supplierReference: string;
    supplierName: string;
    supplier: string;
    approvedDate: string;
    approvedByUser: string;
    projectData: string;
};

export type AprpovedInvoiceReportRequestDTO = {
    id: string;
    toDate: string | null;
    fromDate: string | null;
};
