import { ApiResult, FieldType, isNullOrUndefined, ViewModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import { AppUrls } from "AppUrls";
import { RejectedVarListModel } from "./RejectedVarListModel";
import { RejectedVarListItemsAndRelatedDTO } from "./RejectedVarListItemsAndRelatedDTO";
import { RejectedVarListItemViewModel } from "./RejectedVarListItemViewModel";
import { RejectedVarListFilterViewModel } from "./RejectedVarListFilterViewModel";
//import { RejectedVarListFilterParamsBaseModelDTO } from "./RejectedVarListFilterParamsBaseModel";
import debounce from "lodash-es/debounce";
import { RejectedVarListFilterParamsModelDTO } from "./RejectedVarListFilterParamsModel";
import { VariationStatusTypeModel } from "Globals/Models/Domain/VariationStatusTypeModel";

export class RejectedVarListViewModel extends ViewModelBase<RejectedVarListModel> {
    private static _instance: RejectedVarListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    constructor() {
        super(new RejectedVarListModel(), false);
        this.setDecorators(RejectedVarListModel);
    }

    @computed
    public get getSearchString() {
        return RejectedVarListFilterViewModel.Instance.model.searchText;
    }

    public getSearchStringComputed = () => {
        return computed(() => RejectedVarListFilterViewModel.Instance.model.searchText);
    };

    public handleSearchChange = (val: string, ieid: string) => {
        RejectedVarListFilterViewModel.Instance.setValue("searchText", val);

        if (val.length > 3 || val.length === 0) {
            this.reloadDataDelayed(ieid);
        }
    };

    private reloadDataDelayed = debounce((ieid: string) => {
        this.apiGetAll(ieid);
    }, 500);

    @action
    public cleanUp = () => {
        this.reloadDataDelayed.cancel();
        this.setHasLoaded(false);
    };

    @action
    public resetDateFilters = () => {
        this.model.filterEndDateINV = null;
        this.model.filterStartDateINV = null;
    };

    @observable
    public errorMessage: string = "";

    @observable
    public rejectedVarViewModels: RejectedVarListItemViewModel[] = [];

    @action
    private populateListItemViewModels = (dto: RejectedVarListItemsAndRelatedDTO) => {
        const rejectedVarViewModels2: RejectedVarListItemViewModel[] = [];
        for (const rejectedVarListItem of dto.variations) {
            let itemModel = new RejectedVarListModel();
            itemModel.fromDto(rejectedVarListItem);
            const statusTypeModel = new VariationStatusTypeModel(dto.variationStatusType.find((pst) => pst.id === rejectedVarListItem.variationStatusId));
            rejectedVarViewModels2.push(new RejectedVarListItemViewModel(itemModel, statusTypeModel));
        }
        this.rejectedVarViewModels = rejectedVarViewModels2;
    };

    public apiGetAll = async (ieid: string): Promise<ApiResult<RejectedVarListItemsAndRelatedDTO>> => {
        return this.apiGetDateAllRejectedVars(ieid);
    };

    @action
    public clear = (ieid: string) => {
        RejectedVarListFilterViewModel.Instance.model.reset();
        this.apiGetAll(ieid);
    };

    // Api GetAll Record

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    // Used to ensure that only the results of the last request are used to populate the table.
    private lastRequestId = 0;

    @observable
    public hasLoaded: boolean = false;

    // End Api GetAll Record

    @action
    public setFromDateFilter = (val: Date | null) => {
        this.model.filterStartDateINV = val;
    };

    @action
    public setToDateFilter = (val: Date | null) => {
        this.model.filterEndDateINV = val;
    };

    // RejectedVar by Date

    public apiGetDateAllRejectedVars = async (val: string): Promise<ApiResult<RejectedVarListItemsAndRelatedDTO>> => {
        this.setIsLoading(true);
        const currentRequestId = ++this.lastRequestId;
        let paramsViewModel = RejectedVarListFilterViewModel.Instance;
        let params: RejectedVarListFilterParamsModelDTO = paramsViewModel.model.toDto();
        params.initialLoad = !this.hasLoaded;

        const request: RejectedVarListAndRelatedRequest = {
            id: val,
            startDatefilter: this.model.filterStartDateINV,
            endDateFilter: this.model.filterEndDateINV,
            filterParams: params,
        };
        let apiResult = await this.Post<RejectedVarListItemsAndRelatedDTO>(AppUrls.Server.Variation.GetRejectedVariationsList, request);
        if (apiResult) {
            if (currentRequestId === this.lastRequestId && apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
                RejectedVarListFilterViewModel.Instance.setTotalCount(apiResult.payload.totalCount);

                if (!this.hasLoaded) {
                    RejectedVarListFilterViewModel.Instance.setStatuses(apiResult.payload.variationStatusType, true);
                }
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        this.setHasLoaded(true);
        return apiResult;
    };

    // end RejectedVar By Date

    public async isFieldValid(fieldName: keyof FieldType<RejectedVarListModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}

export interface RequestModel {
    Id: string;
}

export class RejectedVarListAndRelatedRequest {
    id: string = "";
    startDatefilter?: Date | null = null;
    endDateFilter?: Date | null = null;
    filterParams: RejectedVarListFilterParamsModelDTO | null = null;
}
