// Libraries
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";

// Custom
import { PurchaseOrderListViewModel } from "../OrdersAndRequisitions/PurchaseOrderList.ViewModel";
import * as Defaults from "Globals/Defaults/TableOptions";
import { isNullOrUndefined } from "Utils/Utils";
import { Grid, useObserver, useRouter } from "@shoothill/core";

// Styling & Images
import { TableListSearch } from "Components/Table/TableListSearch";
import { DarwinTableToolbar, DarwinTableSearchBox, DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { PurchaseOrderTableModel } from "../OrdersAndRequisitions/PurchaseOrder.Model";
import { TickIcon } from "Globals/Views/IconSVGs/TickIcon";
import { AppUrls } from "AppUrls";
import DownloadDocIcon from "Content/fileIcon.svg";
import { formatCurrencyFromPounds, formatDate, formatEmptyValueForTableDash } from "Utils/Format";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import { PrimaryButton } from "Components/Buttons/Buttons";
interface ITableProps {
    ieId: string;
}

export const POTab: React.FunctionComponent<ITableProps> = ({ ieId }) => {
    const [viewModel] = useState(() => PurchaseOrderListViewModel.Instance);
    const { history } = useRouter();

    useEffect(() => {
        if (isNullOrUndefined(ieId) === false) {
            viewModel.apiGetPurchaseOrders(ieId);
        }
    }, []);

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
    };

    const handleRowClick = (e: any, rowData: PurchaseOrderTableModel | undefined) => {
        if (localStorage.getItem("isPDFClick") === "1") {
            localStorage.removeItem("isPDFClick");
        } else if (rowData !== undefined) {
            history.push(AppUrls.Client.PurchaseOrder.View.replace(":poid", rowData.id));
        }
    };

    const handleGeneratePOPDF = async (rowData: PurchaseOrderTableModel) => {
        localStorage.setItem("isPDFClick", "1");
        await viewModel.apiGeneratePOPDF(rowData.id);
    };

    const exportCSV = () => {
        viewModel.generateCSV(ieId);
    };

    return useObserver(() => {
        const tableOptions = Defaults.GetDarwinTableOptions() as any;

        return (
            <div className="potTabContent">
                <DarwinTableToolbar style={{ padding: 30 }}>
                    <Grid columnGap={30} dc={"1fr 1fr"} rowGap={15} tc={"1fr 1fr"}>
                        <DarwinTableSearchBox>
                            <TableListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                        </DarwinTableSearchBox>
                    </Grid>
                    <br />
                    <Grid columnGap={30} dc={"1fr 1fr 1fr 1fr 1fr 1fr"} rowGap={15} tc={"1fr 1fr 1fr 1fr 1fr 1fr"}>
                        {viewModel && <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading} />}
                    </Grid>
                </DarwinTableToolbar>

                <DarwinTablePageContent>
                    <MaterialTable
                        isLoading={viewModel.IsLoading}
                        columns={[
                            {
                                title: "PO no.",
                                field: "poNumberFormatted",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <>{formatEmptyValueForTableDash(rowData.poNumberFormatted)}</>,
                            },
                            {
                                title: "Revision",
                                field: "revision",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <>{formatEmptyValueForTableDash(rowData.revision)}</>,
                            },
                            {
                                title: "Purchase order description",
                                field: "itemDescription",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <>{formatEmptyValueForTableDash(rowData.itemDescription)}</>,
                            },
                            {
                                title: "Raised by",
                                field: "raisedBy",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <>{formatEmptyValueForTableDash(rowData.raisedBy)}</>,
                            },
                            {
                                title: "PO Date",
                                field: "createdDate",
                                width: "auto",
                                type: "date",
                                render: (rowData: PurchaseOrderTableModel) => (
                                    <>{formatEmptyValueForTableDash(formatDate(rowData.createdDate !== null ? rowData.createdDate.toString() : ""))}</>
                                ),
                            },
                            {
                                title: "Start Date",
                                field: "dateFrom",
                                width: "auto",
                                type: "date",
                                render: (rowData: PurchaseOrderTableModel) => (
                                    <>{formatEmptyValueForTableDash(formatDate(rowData.dateFrom !== null ? rowData.dateFrom.toString() : ""))}</>
                                ),
                            },
                            {
                                title: "Completion Date",
                                field: "dateTo",
                                width: "auto",
                                type: "date",
                                render: (rowData: PurchaseOrderTableModel) => (
                                    <>{formatEmptyValueForTableDash(formatDate(rowData.dateTo !== null ? rowData.dateTo.toString() : ""))}</>
                                ),
                            },
                            {
                                title: "Supplier",
                                field: "supplierName",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <>{formatEmptyValueForTableDash(rowData.supplierName)}</>,
                            },
                            {
                                title: "Value",
                                field: "committedCost",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(rowData.committedCost)}</div>,
                            },
                            {
                                title: "Available balance",
                                field: "availableBalance",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <div style={{ textAlign: "right" }}>{formatCurrencyFromPounds(rowData.availableBalance)}</div>,
                            },
                            {
                                title: "Delivery required",
                                field: "deliveryRequired",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => (
                                    <div className="table-svg-container">{rowData.deliveryRequired === true ? <TickIcon colour="#eb8d00" /> : <>-</>}</div>
                                ),
                            },
                            {
                                title: "Query",
                                field: "query",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => <>{formatEmptyValueForTableDash(rowData.query)}</>,
                            },
                            {
                                title: "PDF",
                                field: "",
                                width: "auto",
                                render: (rowData: PurchaseOrderTableModel) => (
                                    <div className="table-svg-container">
                                        <img src={DownloadDocIcon} onClick={() => handleGeneratePOPDF(rowData)} />
                                    </div>
                                ),
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.returnPurchaseOrders}
                        onRowClick={handleRowClick}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                    />
                </DarwinTablePageContent>
            </div>
        );
    });
};
