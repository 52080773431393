import React from "react";
import { useObserver } from "@shoothill/core";
import { RejectedVarListView } from "Views/Project/Variations/RejectedVariations/RejectedVarListView";
import { RejectedCPSListView } from "Views/Project/Variations/RejectedCPS/RejectedCPSListView";
import { Typography } from "@material-ui/core";

interface IProps {
    ieId: string;
}

export const RejectedVarTab: React.FC<IProps> = (props: IProps) => {
    const renderPage = () => {
        return (
            <div style={{ width: "100%", paddingTop: 22 }}>
                <Typography variant="h2" color="textPrimary">
                    Rejected variations
                </Typography>
                <RejectedVarListView ieId={props.ieId} />
                <Typography variant="h2" color="textPrimary">
                    Rejected client provisional sums
                </Typography>
                <RejectedCPSListView ieId={props.ieId} />
            </div>
        );
    };

    return useObserver(() => renderPage());
};
