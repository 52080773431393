// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { styled, useRouter } from "@shoothill/core";

// Custom
import { IEViewModel as ViewModel } from "./IE.ViewModel";
import { IEViewModel } from "./IEViewModel";
import { PackagesViewModel } from "./PackagesViewModel";
import { useHasRoutedTabs } from "Utils/UseHasRoutedTabs";
import { ITab, Tabs } from "Components/Tabs/Tabs";
import { IEGridItemModel } from "./IEmodels";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { isNullOrUndefined } from "Utils/Utils";

// Views
import { IETab } from "./TabContent/IE.Tab";
import { PRTab } from "./TabContent/PR.Tab";
import { POTab } from "./TabContent/PO.Tab";
import { ReportsTab } from "./TabContent/Reports.Tab";
import { IEItemsWrapper } from "./IETables/IEItemsWrapper";
import { IELineModal } from "./Modals/IELineModal";
import { IEVariationModal } from "./Modals/IEVariationModal";

// Styling & Images
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { CustomArrow } from "../CustomComponents";
import { BackButtonHolder, BorderedActionButtonHolder, CentralDropdownContainer, IEButton } from "./IETables/IEGrid.Styles";
import { AppUrls } from "AppUrls";
import { VariationListView } from "../Variations/VariationListView";
import { Box } from "@material-ui/core";
import moment from "moment";
import { VariationListViewModel } from "../Variations/VariationListViewModel";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { InvoiceListView } from "Views/Invoices/InvoiceListView";
import { CentralListViewModel } from "Views/Central/CentralListViewModel";
import { IEGridItemViewModel } from "./IEmodels/IEGridItemViewModel";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { StoresInstance } from "Globals/Stores";
import { InvoiceListViewModel } from "Views/Invoices/InvoiceListViewModel";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { VariationTypeEnum } from "Views/Variation/Form/VariationModel";
import { RejectedVarTab } from "./TabContent/RejectedVar.Tab";

interface Props {
    ieId: string | null;
    isCentral: boolean;
}

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

export const IEView: React.FunctionComponent<Props> = (props) => {
    const router = useRouter();
    const { history } = useRouter();
    const [viewModel] = useState(() => ViewModel.Instance);
    const packagesViewModel = PackagesViewModel.Instance;
    const ieViewModel = IEViewModel.Instance;
    const hashTab = useHasRoutedTabs(viewModel.returnTabOptions);
    const [defaultTab, setDefaultTab] = useState<string | null>(null);
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const [invoiceListViewModel] = useState(() => InvoiceListViewModel.Instance);
    const [variationListViewModel] = useState(() => VariationListViewModel.Instance);
    const [centralViewModel] = useState(() => CentralListViewModel.Instance);
    let { ieid: ieidParam } = router.match.params as any;
    const [ieid, setIEId] = useState(ieidParam ? ieidParam : props.ieId);

    useEffect(() => {
        //let { ieid } = router.match.params as any;
        PackagesViewModel.Instance.apiGetRelated(ieid);

        IEGridItemViewModel.Instance.setIsCentral(props.isCentral ? props.isCentral : false);

        if (props.isCentral) {
            if (StoresInstance.Domain.AccountStore.getCanViewCentralIEs) {
                setDefaultTab(viewModel.TAB_IE);
                setSelectedTab(viewModel.TAB_IE);
            } else {
                setDefaultTab(viewModel.TAB_PR);
                setSelectedTab(viewModel.TAB_PR);
            }
        } else {
            setDefaultTab(viewModel.TAB_IE);
            setSelectedTab(viewModel.TAB_IE);
        }

        //setInitialTab();

        if (isNullOrUndefined(ieid) === false) {
            viewModel.apiGetIEView(ieid);
        }

        return () => {
            variationListViewModel.resetDateFilters();
            invoiceListViewModel.resetDateFilters();
        };
    }, []);

    // const setInitialTab = () => {
    //     let { hash } = router.location;
    //     const hashClean = hash.replace("#", "");

    //     if (hashClean !== "") {
    //         setSelectedTab(hashClean);
    //     }
    // };

    //snackbar stuff
    const [snackActive, setSnackActive] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>("");
    const [snackType, setSnackType] = useState<string>("info");

    // Add/Edit IE Line/Description modal
    const [ieLineModalActive, setIELineModalActive] = useState<boolean>(false);
    const [itemModel, setItemModel] = useState<IEGridItemModel>(new IEGridItemModel());
    const [ieLineParent, setIELineParent] = useState<string>("");
    const [categoryId, setCategoryId] = useState<number>(-1);
    const [subcategoryId, setSubcategoryId] = useState<number>(-1);
    const [descriptionId, setDescriptionId] = useState<number>(-1);
    const [descriptionOther, setDescriptionOther] = useState<string>("");

    const [ieRow, setIERow] = useState<string>("");
    const [ieTitle, setIETitle] = useState<string | undefined>("");
    const onEndDateChanged = (date: string | null) => {
        //let { ieid } = router.match.params as any;
        variationListViewModel.setValue("filterEndDateVAR", date === null ? date : moment(date).toISOString());
        let isValid = variationListViewModel.model.validateFilters();

        if (isValid) {
            variationListViewModel.GetApprovedVariationsList(ieid);
        }
    };

    const onCPSEndDateChanged = (date: string | null) => {
        //let { ieid } = router.match.params as any;
        variationListViewModel.setValue("filterEndDateCPS", date === null ? date : moment(date).toISOString());
        let isValid = variationListViewModel.model.validateFilters();

        if (isValid) {
            variationListViewModel.GetApprovedVariationsList(ieid);
        }
    };

    const resetDateFilters = () => {
        //let { ieid } = router.match.params as any;
        variationListViewModel.resetDateFilters();
        variationListViewModel.GetApprovedVariationsList(ieid);
    };

    // IE Tab Invoice date Change

    const onInvoiceStartDateChanged = (date: string | null) => {
        //let { ieid } = router.match.params as any;
        invoiceListViewModel.setValue("filterStartDateINV", date === null ? date : moment(date).toDate());
        let isValid = invoiceListViewModel.model.validateFilters();
        if (isValid) {
            invoiceListViewModel.apiGetDateAllInvoices(ieid);
        }
    };

    const onInvoiceEndDateChanged = (date: string | null) => {
        //let { ieid } = router.match.params as any;
        invoiceListViewModel.setValue("filterEndDateINV", date === null ? date : moment(date).toDate());
        let isValid = invoiceListViewModel.model.validateFilters();
        if (isValid) {
            invoiceListViewModel.apiGetDateAllInvoices(ieid);
        }
    };

    const resetInvoiceDateFilters = () => {
        //let { ieid } = router.match.params as any;
        invoiceListViewModel.resetDateFilters();
        invoiceListViewModel.apiGetDateAllInvoices(ieid);
    };

    // End IE Tab Invoice date Change

    const handleIERow = (val: string) => {
        setIERow("");
        setSelectedTab(defaultTab);
        setIERow(val);
    };

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);

        if (tab.key !== viewModel.TAB_VAR) {
            variationListViewModel.setValue("filterEndDateVAR", null);
        }

        if (tab.key !== viewModel.TAB_CPS) {
            variationListViewModel.setValue("filterEndDateCPS", null);
        }

        if (tab.key !== viewModel.TAB_INV) {
            invoiceListViewModel.setValue("filterStartDateINV", null);
            invoiceListViewModel.setValue("filterEndDateINV", null);
        }
    };

    const addNewIELineClick = () => {
        if (StoresInstance.Domain.AccountStore.getCanAddIELine) {
            setCategoryId(-1);
            setSubcategoryId(-1);
            setDescriptionId(-2);
            setDescriptionOther("");
            setIELineModalActive(true);
            setItemModel(new IEGridItemModel());
        }
    };

    const handleSaveVariation = (saved: string) => {
        if (saved === "successful") {
            packagesViewModel.apiGetRelated(ieid);
            viewModel.setVariationModal(false);
            viewModel.apiGetIEView(viewModel.workingId);
            setSnackMessage("Variation saved/updated");
            setSnackType("success");
            setSnackActive(true);
        } else {
            setSnackMessage("Failed to save Variation");
            setSnackType("error");
            setSnackActive(true);
        }
    };

    const handleSaveIELine = (saved: string) => {
        if (StoresInstance.Domain.AccountStore.getCanAddIELine) {
            if (saved === "successful") {
                setIERow("");
                viewModel.apiGetIEView(viewModel.workingId);
                setIERow(ieRow);
                setIELineModalActive(false);
                setSnackMessage("I & E line added/updated");
                setSnackType("success");
                setSnackActive(true);
            } else {
                setSnackMessage("Failed to save I & E Line");
                setSnackType("error");
                setSnackActive(true);
            }
        }
    };

    const renderIETabs = () => {
        switch (selectedTab) {
            case viewModel.TAB_VAR:
                return (
                    <>
                        <VariationListView
                            ieId={viewModel.workingId}
                            variationType={VariationTypeEnum.Variation}
                            variationTypeName={"Variation"}
                            packagesViewModel={packagesViewModel}
                            chosenId={(i) => {
                                handleIERow(i);
                            }}
                            rowToEdit={(row, parent, iePackage) => {
                                setIELineParent(parent);
                                setIELineModalActive(true);
                                setCategoryId(iePackage.categoryId);
                                setSubcategoryId(iePackage.subCategoryId);
                                setDescriptionId(iePackage.descriptionId);
                                setDescriptionOther(row.descriptionOther);
                            }}
                            rowTitle={(i) => setIETitle(i)}
                        />
                    </>
                );
                break;
            case viewModel.TAB_CPS:
                return (
                    <>
                        <VariationListView
                            ieId={viewModel.workingId}
                            variationType={VariationTypeEnum.ClientProvisionalSum}
                            variationTypeName={"Client Provisional Sum"}
                            packagesViewModel={packagesViewModel}
                            chosenId={(i) => {
                                handleIERow(i);
                            }}
                            rowToEdit={(row, parent, iePackage) => {
                                setIELineParent(parent);
                                setIELineModalActive(true);
                                setCategoryId(iePackage.categoryId);
                                setSubcategoryId(iePackage.subCategoryId);
                                setDescriptionId(iePackage.descriptionId);
                                setDescriptionOther(row.descriptionOther);
                            }}
                            rowTitle={(i) => setIETitle(i)}
                        />
                    </>
                );
                break;
            case viewModel.TAB_PR:
                return (
                    <>
                        <PRTab ieId={viewModel.workingId} />
                    </>
                );
                break;

            case viewModel.TAB_PO:
                return (
                    <>
                        <POTab ieId={viewModel.workingId} />
                    </>
                );
                break;

            case viewModel.TAB_INV:
                return (
                    <>
                        {/* <InvoiceTab /> */}
                        <InvoiceListView ieId={viewModel.workingId} />
                    </>
                );
                break;

            case viewModel.TAB_REP:
                return (
                    <>
                        <ReportsTab />
                        {/* <VariationListView /> */}
                    </>
                );
                break;

            case viewModel.TAB_IE:
                return (
                    <>
                        <IETab
                            IEid={viewModel.workingId}
                            viewModel={viewModel}
                            packagesViewModel={packagesViewModel}
                            chosenId={(i) => {
                                handleIERow(i);
                            }}
                            rowToEdit={(row, parent, iePackage) => {
                                setItemModel(row);
                                setIELineParent(parent);
                                setIELineModalActive(true);
                                setCategoryId(iePackage.categoryId);
                                setSubcategoryId(iePackage.subCategoryId);
                                setDescriptionId(iePackage.descriptionId);
                                setDescriptionOther(row.descriptionOther);
                            }}
                            rowTitle={(i) => setIETitle(i)}
                        />
                    </>
                );
                break;
            case viewModel.TAB_REJECTEDVAR:
                return (
                    <>
                        <RejectedVarTab ieId={viewModel.workingId} />
                    </>
                );
                break;
        }
    };

    const renderIEItems = () => {
        return (
            <>
                {selectedTab !== null && <Tabs tabs={viewModel.returnTabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />}
                {/* <Tabs tabs={viewModel.tabDraftOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" /> */}
                <div className="content" style={{ padding: "0px 0px 21px" }}>
                    {renderIETabs()}
                </div>
            </>
        );
    };

    return useObserver(() => (
        <DetailsPage className="projectSide">
            {!centralViewModel.hasIEId && (
                <BackButtonHolder
                    onClick={() => {
                        setSelectedTab(defaultTab);
                        setIERow("");
                        history.push(AppUrls.Client.Project.Commercial.replace(":projectid", viewModel.projectId));
                    }}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>
                            Back to Commercial ({viewModel.projectTitle})
                        </div>
                    </div>
                </BackButtonHolder>
            )}
            {selectedTab === viewModel.TAB_VAR && (
                <DateFilterContainer>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            displayName="End date:"
                            execute={(value: string | null) => onEndDateChanged(value)}
                            placeholder="Please select"
                            value={variationListViewModel.model.filterEndDateVAR}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            defaultToStartOfDay={false}
                        />
                    </Box>

                    <PrimaryButton displayName="Reset date" execute={resetDateFilters} fullWidth={true} canExecute={!variationListViewModel.IsLoading} />
                </DateFilterContainer>
            )}
            {selectedTab === viewModel.TAB_CPS && (
                <DateFilterContainer>
                    <Box maxWidth="200px">
                        <DarwinDateSelect
                            displayName="End date:"
                            execute={(value: string | null) => onCPSEndDateChanged(value)}
                            placeholder="Please select"
                            value={variationListViewModel.model.filterEndDateCPS}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            defaultToStartOfDay={false}
                        />
                    </Box>

                    <PrimaryButton displayName="Reset date" execute={resetDateFilters} fullWidth={true} canExecute={!variationListViewModel.IsLoading} />
                </DateFilterContainer>
            )}
            {selectedTab === viewModel.TAB_INV && (
                <DateFilterContainer className="dateField">
                    <Box maxWidth="150px" marginRight={"30px"}>
                        <DarwinDateSelect
                            displayName="Start date(IW 14):"
                            execute={(value: string | null) => onInvoiceStartDateChanged(value)}
                            placeholder="Please select"
                            value={invoiceListViewModel.model.getFilterStartDateINV}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!invoiceListViewModel.IsLoading}
                        />
                    </Box>
                    <Box maxWidth="150px">
                        <DarwinDateSelect
                            displayName="End date (IW 14):"
                            execute={(value: string | null) => onInvoiceEndDateChanged(value)}
                            placeholder="Please select"
                            value={invoiceListViewModel.model.getFilterEndDateINV}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!invoiceListViewModel.IsLoading}
                            defaultToStartOfDay={false}
                        />
                    </Box>
                    <PrimaryButton displayName="Reset date" execute={resetInvoiceDateFilters} fullWidth={true} canExecute={!invoiceListViewModel.IsLoading} />
                </DateFilterContainer>
            )}
            <BorderedActionButtonHolder>
                <h1 style={{ paddingLeft: "15px" }}>{viewModel.returnIEDescription}</h1>
                {selectedTab === viewModel.TAB_IE && !IEGridItemViewModel.Instance.isCentral && StoresInstance.Domain.AccountStore.getCanAddIELine && (
                    <>
                        <IEButton onClick={() => addNewIELineClick()} style={{ margin: "10px 0px 10px 10px" }}>
                            Add new line
                        </IEButton>
                    </>
                )}
                {(selectedTab === viewModel.TAB_IE || selectedTab === viewModel.TAB_PR || selectedTab === viewModel.TAB_PO) && (
                    <>
                        {viewModel.projectStatusType !== "DRAFT" && (
                            <>
                                <IEButton
                                    onClick={() => history.push(AppUrls.Client.PurchaseOrder.AddIE.replace(":projectid", viewModel.projectId).replace(":ie", viewModel.workingId))}
                                    style={{ margin: "10px 0px" }}
                                >
                                    Raise requisition
                                </IEButton>
                            </>
                        )}
                    </>
                )}
                {selectedTab === viewModel.TAB_VAR && (
                    <>
                        <IEButton onClick={() => history.push(AppUrls.Client.Variation.AddIE.replace(":ieid", viewModel.workingId))} style={{ margin: "10px 0px" }}>
                            Create new Variation
                        </IEButton>
                    </>
                )}
                {selectedTab === viewModel.TAB_CPS && (
                    <>
                        <IEButton onClick={() => history.push(AppUrls.Client.ClientProvisionalSum.AddIE.replace(":ieid", viewModel.workingId))} style={{ margin: "10px 0px" }}>
                            Create new client provisional sum
                        </IEButton>
                    </>
                )}
                {selectedTab === viewModel.TAB_INV && StoresInstance.Domain.AccountStore.getCanAddInvoice && (
                    <>
                        <IEButton onClick={() => history.push(AppUrls.Client.Invoicing.AddIE.replace(":ieid", viewModel.workingId))} style={{ margin: "10px 0px" }}>
                            Add new invoice
                        </IEButton>
                    </>
                )}
            </BorderedActionButtonHolder>

            {IEGridItemViewModel.Instance.isCentral && StoresInstance.Domain.AccountStore.CanViewCentralIEs && (
                <CentralDropdownContainer>
                    <DarwinSelect
                        displayName="Central:"
                        execute={CentralListViewModel.Instance.setCurrentCentralProject}
                        fullWidth={true}
                        getOptionLabel={(option: any) => option.displayName}
                        options={CentralListViewModel.Instance.centralProjectOptions}
                        placeholder="Please select"
                        value={CentralListViewModel.Instance.currentCentralProject}
                        canExecute={
                            IEGridItemViewModel.Instance.isCentral &&
                            !IEGridItemViewModel.Instance.IsLoading &&
                            !CentralListViewModel.Instance.isLoadingIE &&
                            !CentralListViewModel.Instance.IsLoading
                        }
                    />
                </CentralDropdownContainer>
            )}

            <IEItemsWrapper>
                <div className="content" style={{ position: "relative" }}>
                    {selectedTab !== null && renderIEItems()}
                </div>
            </IEItemsWrapper>
            {viewModel.showVariationModal && (
                <IEVariationModal
                    open={viewModel.showVariationModal}
                    ieId={viewModel.workingId}
                    onClose={() => viewModel.setVariationModal(false)}
                    saveResponse={(r) => handleSaveVariation(r)}
                    viewModel={ieViewModel}
                />
            )}
            {ieLineModalActive && (
                <IELineModal
                    open={ieLineModalActive}
                    onClose={() => setIELineModalActive(false)}
                    itemModel={itemModel}
                    ieId={ieRow}
                    parentId={ieLineParent}
                    categoryId={categoryId}
                    subcategoryId={subcategoryId}
                    descriptionId={descriptionId}
                    descriptionOther={descriptionOther}
                    saveResponse={(val) => handleSaveIELine(val)}
                />
            )}
            <SnackBar messageText={snackMessage} messageType={snackType} active={snackActive} closeOption={() => setSnackActive(false)} autoHideDuration={5000} />
        </DetailsPage>
    ));
};

const DateFilterContainer: any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    margin: 0px auto;
    width: 97%;
    padding: 10px 0px 10px;

    > label {
        width: unset;

        > div {
            > div {
                margin: 0px;
            }
        }
    }

    > button {
        width: auto;
        white-space: nowrap;
        min-width: 110px;
        margin-left: 30px;

        span {
            font-size: ${pxToRem(14)} !important;
        }
    }
`;
