import { FieldType, KeyValuePair, observable, ViewModelBase } from "@shoothill/core";
import { action } from "mobx";
import { RejectedCPSListFilterParamsModel } from "./RejectedCPSListFilterParamsModel";
import { PaginationDirection } from "Components/Table/DarwinTableV2";
import { VariationStatusTypeDTO } from "Globals/Models/Domain/VariationStatusTypeModel";

export class RejectedCPSListFilterViewModel extends ViewModelBase<RejectedCPSListFilterParamsModel> {
    private static _intsance: RejectedCPSListFilterViewModel;
    public static get Instance() {
        return this._intsance || (this._intsance = new this());
    }

    constructor() {
        super(new RejectedCPSListFilterParamsModel(), false);
        this.setDecorators(RejectedCPSListFilterParamsModel);
    }

    @observable
    public readonly statusOptions: KeyValuePair[] = [];

    public getStatusOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.statusOptions === undefined ? [] : this.statusOptions.slice();
    };

    @action
    public setStatuses(statusOptions: VariationStatusTypeDTO[], resetFilters: boolean) {
        if (this.statusOptions !== null && this.statusOptions.length === 0) {
            if (statusOptions.length > 0) {
                this.statusOptions.push(
                    ...statusOptions.map((item: VariationStatusTypeDTO) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public handleSorting(sortCol: string, sortDir: string) {
        this.model.sortBy = sortCol;
        this.model.sortDirection = sortDir;
    }

    @action
    public handleRowsPerPageChange(pageSize: number) {
        this.model.pageSize = pageSize;
    }

    @action
    public setTotalCount = (val: number) => {
        this.model.totalCount = val;
    };

    @action
    public setPageNumber = (val: number) => {
        this.model.pageNumber = val;
    };

    @action
    public handlePageChange = async (change: PaginationDirection) => {
        const pageNumber: number = this.model.pageNumber;
        const pageCount: number = this.model.pageCount;

        switch (change) {
            case PaginationDirection.NEXT:
                pageNumber < pageCount ? this.setPageNumber(pageNumber + 1) : this.setPageNumber(1);

                break;
            case PaginationDirection.BACK:
                pageNumber > 1 ? this.setPageNumber(pageNumber - 1) : this.setPageNumber(1);

                break;
            case PaginationDirection.START:
                this.setPageNumber(1);

                break;
            case PaginationDirection.END:
                this.setPageNumber(pageCount);

                break;
            default:
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<RejectedCPSListFilterParamsModel>): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
