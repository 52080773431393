import { getHistory, FieldType, ViewModelBase, ApiResult, KeyValuePair } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import {
    AllProjectApprovedInvoiceReportAllProjectDTO,
    AllProjectApprovedInvoiceReportResponseDTO,
    AllProjectApprovedInvoicesReportModel,
    AllProjectApprovedInvoicesReportRequestDTO,
} from "./AllProjectApprovedInvoicesReportModel";
import moment from "moment";
import { AppUrls } from "AppUrls";
import { AllProjectReportAllProjectDTO, AllProjectReportResponseDTO } from "../Shared/AllProjectReportModelBase";
import { csvAxiosRequestConfig, exportCSV } from "Utils/Utils";

export class AllProjectApprovedInvoicesReportViewModel extends ViewModelBase<AllProjectApprovedInvoicesReportModel> {
    // #region Constructors and Disposers

    constructor() {
        super(new AllProjectApprovedInvoicesReportModel());

        this.setDecorators(AllProjectApprovedInvoicesReportViewModel);
    }

    @observable public reportData: AllProjectApprovedInvoiceReportAllProjectDTO[] | null = null;

    @observable public canExportCSV: boolean = false;

    @action
    public setCanExportCSV(val: boolean) {
        this.canExportCSV = val;
    }

    @computed
    public get getTopDateFormatted(): string {
        return this.model.reportToDate ? moment(this.model.reportToDate).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    @computed
    public get getBottomDateFormatted(): string {
        return this.model.reportFromDate ? moment(this.model.reportFromDate).format("DD/MM/YYYY").toString() : "Please select a date";
    }

    // #region Client Actions

    @action
    public runReport = async (): Promise<ApiResult<AllProjectApprovedInvoiceReportResponseDTO>> => {
        this.setIsLoading(true);

        const request: AllProjectApprovedInvoicesReportRequestDTO = {
            fromDate: this.model.reportFromDate,
            toDate: this.model.reportToDate,
        };
        let apiResult = await this.Post<AllProjectApprovedInvoiceReportResponseDTO>(AppUrls.Server.Report.AllProjects.GetAllProjectsApprovedInvoicesReport, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.reportData = apiResult.payload.approvedInvoices;
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
        return apiResult;
    };

    @action
    public generateAllProjectApprovedInvoicesReportCSV = async () => {
        this.setIsLoading(true);

        const request: AllProjectApprovedInvoicesReportRequestDTO = {
            fromDate: this.model.reportFromDate,
            toDate: this.model.reportToDate,
        };

        await exportCSV(AppUrls.Server.Report.AllProjects.GenerateAllProjectsApprovedInvoicesReportCSV, request, await this.getConfig(true, csvAxiosRequestConfig)).finally(() =>
            this.setIsLoading(false),
        );
    };

    public server: ServerViewModel = new ServerViewModel();

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel = (): void => {
        getHistory().goBack();
    };

    // #endregion Client Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<AllProjectApprovedInvoicesReportModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
