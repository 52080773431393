import React, { useEffect, useState } from "react";
import { isNullOrUndefined, useObserver } from "@shoothill/core";
import { RejectedVarListViewModel } from "./RejectedVarListViewModel";
import { Grid } from "@shoothill/core";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { RejectedVarListItemViewModel } from "./RejectedVarListItemViewModel";
import { ColumnHelper, createColumnHelper } from "@tanstack/react-table";
import { formatDate, formatEmptyValueForTableDash } from "Utils/Format";
import { DarwinTableV2, PaginationDirection, SortOrderDirection } from "Components/Table/DarwinTableV2";
import { RejectedVarListFilterViewModel } from "./RejectedVarListFilterViewModel";
import { VariationStatus, VariationStatusHelpers } from "../VariationStatusEnum";
import { theme } from "Globals/Styles/AppTheme";

interface IProps {
    ieId: string;
}

export const RejectedVarListView: React.FunctionComponent<IProps> = (props: IProps) => {
    const [viewModel] = useState(() => RejectedVarListViewModel.Instance);

    const filtersViewModel = RejectedVarListFilterViewModel.Instance;

    useEffect(() => {
        if (isNullOrUndefined(props.ieId) === false) {
            viewModel.apiGetAll(props.ieId);
        }

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    // const handleRowClick = (rowId: string | number) => {
    //     const id = rowId as string;
    //     const url: string = AppUrls.Client.RejectedVar.Edit.replace(":invoiceid", id).replace(":approvalMode", "false");
    //     history.push(url);
    // };

    // const setSearchString = (value: string) => {
    //     viewModel.handleSearchChange(value);
    // };

    const handleSorting = async (columnName: string, orderBy: SortOrderDirection) => {
        if (viewModel.hasLoaded) {
            filtersViewModel.handleSorting(columnName, orderBy);
            loadData();
        }
    };

    const handlePageChange = async (change: PaginationDirection) => {
        filtersViewModel.handlePageChange(change);
        loadData();
    };

    const handleRowsPerPageChange = async (pageSize: number) => {
        filtersViewModel.handleRowsPerPageChange(pageSize);
        loadData();
    };

    const handlePageValueChange = async (page: number) => {
        filtersViewModel.setPageNumber(page);
        loadData();
    };

    const loadData = async () => {
        await viewModel.apiGetAll(props.ieId);
    };

    const columnHelper: ColumnHelper<RejectedVarListItemViewModel> = createColumnHelper<RejectedVarListItemViewModel>();

    const columns = [
        columnHelper.accessor("formattedVariationNumber", {
            header: () => "Variation number",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("description", {
            header: () => "Description",
            cell: (info) => <>{formatEmptyValueForTableDash(info.getValue())}</>,
            enableResizing: false,
        }),
        columnHelper.accessor("lastUpdatedDate", {
            header: () => "Last updated date",
            cell: (info) => <>{formatEmptyValueForTableDash(formatDate(info.getValue() !== null ? info.getValue()!.toString() : ""))}</>,
            size: 100,
            enableResizing: false,
        }),
        columnHelper.accessor("statusName", {
            header: () => "Status",
            cell: (info) => (
                <StatusCell
                    className={VariationStatusHelpers.getClassName(info.row.original.statusName.toUpperCase() as VariationStatus)}
                    style={{
                        backgroundColor: VariationStatusHelpers.getCellColour(info.row.original.statusName.toUpperCase() as VariationStatus, theme),
                        width: 275,
                        color: VariationStatusHelpers.getCellTextColour(info.row.original.statusName.toUpperCase() as VariationStatus),
                    }}
                >
                    <div>{VariationStatusHelpers.getText(info.row.original.statusName.toUpperCase() as VariationStatus)}</div>
                </StatusCell>
            ),
            enableResizing: false,
        }),
    ];

    const renderPage = () => {
        return (
            <Grid margin={"20px 0"}>
                <DarwinTablePageContent className="rejected-cps-Table">
                    <DarwinTableV2
                        columns={columns}
                        data={viewModel.rejectedVarViewModels}
                        //onRowClick={handleRowClick}
                        onSortChange={handleSorting}
                        onChangeRowPerPage={handleRowsPerPageChange}
                        onChangePage={handlePageChange}
                        onChangePageNumber={handlePageValueChange}
                        initialSortColumn={filtersViewModel.model.sortBy}
                        initialSortDirection={SortOrderDirection[filtersViewModel.model.sortDirection as keyof typeof SortOrderDirection]}
                        showPagination={true}
                        totalRowCount={filtersViewModel.model.totalCount}
                        totalPageCount={filtersViewModel.model.pageCount}
                        pageNumber={filtersViewModel.model.pageNumber}
                        pageSize={filtersViewModel.model.pageSize}
                        isProcessing={viewModel.IsLoading}
                    />
                </DarwinTablePageContent>
            </Grid>
        );
    };

    return useObserver(() => renderPage());
};
