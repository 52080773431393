import { action, computed } from "mobx";

export default class AccountViewModel {
    private accountStore: any = null;

    constructor(context: any) {
        this.accountStore = context;
    }

    @computed get GetUseAdminGrids(): boolean {
        return this.accountStore.GetUseAdminGrids;
    }

    @computed get GetUseAdminControlsLight(): boolean {
        return this.accountStore.GetUseAdminControlsLight;
    }
}
