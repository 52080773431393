// Libraries
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Loader } from "@shoothill/core";
import * as Defaults from "Globals/Defaults/TableOptions";

// Custom
import { DetailsPage } from "Globals/Views/DetailsPage/DetailsPage.styles";
import { ControlLabel } from "Components/Form/ControlLabel";
import { Box } from "@material-ui/core";
import moment from "moment";
import { DarwinDateSelect } from "Components/DateSelect/DarwinDateSelect";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { AllProjectApprovedInvoicesReportViewModel } from "./AllProjectApprovedInvoicesReportViewModel";
import { IEItemsWrapper } from "Views/Project/Commercial/IETables/IEItemsWrapper";
import { AllInvoiceReportDatePickerContainer, RunReportButtonContainer } from "Views/Report/Report.styles";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import MaterialTable from "material-table";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import { formatCurrencyFromPounds, formatDate } from "Utils/Format";
import { AllProjectApprovedInvoiceReportAllProjectDTO } from "./AllProjectApprovedInvoicesReportModel";

// Styling & Images

moment.locale("en-GB", {
    week: {
        dow: 1,
    },
});

interface Props {}

export const AllProjectApprovedInvoicesReportView: React.FunctionComponent<Props> = (props: Props) => {
    const [viewModel] = useState(() => new AllProjectApprovedInvoicesReportViewModel());

    useEffect(() => {
        return () => {
            viewModel.reset();
        };
    }, []);

    const onReportToDateChanged = (date: string | null) => {
        viewModel.setValue("reportToDate", date === null ? date : moment(date).endOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const onReportFromDateChanged = (date: string | null) => {
        viewModel.setValue("reportFromDate", date === null ? date : moment(date).endOf("day").toISOString());
        viewModel.setCanExportCSV(false);
    };

    const runReport = () => {
        viewModel.runReport();
        viewModel.setCanExportCSV(true);
    };

    const exportCSV = () => {
        viewModel.generateAllProjectApprovedInvoicesReportCSV();
    };

    return useObserver(() => (
        <DetailsPage>
            <AllInvoiceReportDatePickerContainer>
                <ControlLabel label="Date comparison:" htmlFor="start-date" />
                <Box>
                    <Box maxWidth="300px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportFromDateChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportFromDate}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            displayName="From"
                            defaultToStartOfDay={false}
                        />
                    </Box>
                    <Box maxWidth="300px">
                        <DarwinDateSelect
                            execute={(value: string | null) => onReportToDateChanged(value)}
                            placeholder="Please select"
                            value={viewModel.model.reportToDate}
                            maxDate={moment.utc().toDate()}
                            clearable
                            format="DD/MM/YYYY"
                            canExecute={!viewModel.IsLoading}
                            displayName="To"
                            defaultToStartOfDay={false}
                        />
                    </Box>
                </Box>
            </AllInvoiceReportDatePickerContainer>

            <RunReportButtonContainer>
                <PrimaryButton displayName={"Run report"} execute={runReport} fullWidth={false} canExecute={!viewModel.IsLoading} />
                {viewModel.reportData && (
                    <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!viewModel.IsLoading && viewModel.canExportCSV} />
                )}
            </RunReportButtonContainer>

            <IEItemsWrapper>
                <div className="content" style={{ paddingTop: "30px", position: "relative", minWidth: "1715px" }}>
                    {!viewModel.IsLoading && viewModel.reportData && (
                        <DarwinTablePageContent>
                            <MaterialTable
                                columns={[
                                    { title: "Invoice number", field: "invoiceNumber" },
                                    {
                                        title: "Invoice value (Net)",
                                        field: "invoiceValueNet",
                                        render: (rowData: AllProjectApprovedInvoiceReportAllProjectDTO) =>
                                            rowData.invoiceValueNet ? formatCurrencyFromPounds(rowData.invoiceValueNet) : "",
                                    },
                                    {
                                        title: "Invoice value (Gross)",
                                        field: "invoiceValueGross",
                                        render: (rowData: AllProjectApprovedInvoiceReportAllProjectDTO) =>
                                            rowData.invoiceValueGross ? formatCurrencyFromPounds(rowData.invoiceValueGross) : "",
                                    },
                                    {
                                        title: "Invoice date",
                                        field: "invoiceDate",
                                        render: (rowData: AllProjectApprovedInvoiceReportAllProjectDTO) => (
                                            <>{formatDate(rowData.invoiceDate !== null ? rowData.invoiceDate.toString() : "")}</>
                                        ),
                                        defaultSort: "desc",
                                    },
                                    {
                                        title: "Due date",
                                        field: "dueDate",
                                        render: (rowData: AllProjectApprovedInvoiceReportAllProjectDTO) => (
                                            <>{formatDate(rowData.dueDate !== null ? rowData.dueDate.toString() : "")}</>
                                        ),
                                    },
                                    {
                                        title: "Posted date",
                                        field: "postedDate",
                                        render: (rowData: AllProjectApprovedInvoiceReportAllProjectDTO) => (
                                            <>{formatDate(rowData.postedDate !== null ? rowData.postedDate.toString() : "")}</>
                                        ),
                                    },
                                    { title: "Supplier Reference", field: "supplierReference" },
                                    { title: "Supplier Name", field: "supplierName" },
                                    {
                                        title: "Approved date",
                                        field: "approvedDate",
                                        render: (rowData: AllProjectApprovedInvoiceReportAllProjectDTO) => (
                                            <>{formatDate(rowData.approvedDate !== null ? rowData.approvedDate.toString() : "")}</>
                                        ),
                                    },
                                    { title: "Approved by user", field: "approvedByUser" },
                                    { title: "Project(s)", field: "projectData" },
                                ]}
                                components={{
                                    Pagination: PatchedPagination,
                                }}
                                options={Defaults.GetDarwinTableOptionsNoSearchV2()}
                                data={viewModel.reportData}
                                isLoading={viewModel.IsLoading}
                                title=""
                            />
                        </DarwinTablePageContent>
                    )}
                    {viewModel.IsLoading && <Loader />}
                </div>
            </IEItemsWrapper>
        </DetailsPage>
    ));
};
