import { isNullOrUndefined } from "Utils/Utils";
import * as MobX from "mobx";

import { FieldType, KeyValuePair, sortByString } from "@shoothill/core";
import { GenericIncludeDeleted, LoginModel, GenericId, Role } from "Globals/Models";
import { User, UserDTO } from "Globals/Models/User";
import { UserListItem } from "Globals/Models/UserListItem";

import { action, computed, observable, runInAction } from "mobx";
import type { IObservableArray } from "mobx";

import { ApiResult } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { StoresInstance } from "Globals/Stores/Stores";
import { UserStore } from "Globals/Stores/Domain";
import { ViewModelBase } from "@shoothill/core";
import { IsEmailInUseRequest } from "Globals/Models/Domain/IsEmailInUseRequest";
import { ProjectListItemsAndRelatedDTO } from "../../Views/Project/ProjectListItemsAndRelatedDTO";
import { ProjectListModel } from "../../Views/Project/ProjectListModel";

const domainStores = StoresInstance.Domain;

export default class UserViewModel extends ViewModelBase<User> {
    //Singleton instance of class
    private static _instance: UserViewModel;
    private roleStore = StoresInstance.Domain.RoleStore;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @MobX.observable public userCount: number = 0;
    @MobX.observable private ascOrder = true;
    private userStore: UserStore = StoresInstance.Domain.UserStore;
    @MobX.observable public Valid: boolean = false;
    @MobX.observable public resetLoginAttemptsError: string = "";
    @MobX.observable public errorMessage: string = "";
    @MobX.observable public showAEModal: boolean = false;
    @MobX.observable
    public projectListModels: IObservableArray<ProjectListModel> = observable<ProjectListModel>([]);

    @observable
    public readonly projectOptions: KeyValuePair[] = [];

    @observable
    public readonly roleOptions: KeyValuePair[] = [];

    public getProjectOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.projectOptions === undefined ? [] : this.projectOptions.slice();
    };

    @action
    public setProjects(projectListModels: ProjectListModel[]) {
        if (this.projectOptions !== null && this.projectOptions.length === 0) {
            if (projectListModels.length > 0) {
                this.projectOptions.push(
                    ...projectListModels.map((item: ProjectListModel) => {
                        return { key: item.name, value: item.id };
                    }),
                );
            }
        }
    }

    @action
    public setRoles() {
        let roles: Role[] = StoresInstance.Domain.RoleStore.getRoles;

        if (this.roleOptions !== null && this.roleOptions.length === 0) {
            if (roles.length > 0) {
                this.roleOptions.push(
                    ...roles.map((item: Role) => {
                        return { key: item.displayName, value: item.id };
                    }),
                );
            }
        }
    }

    public getRoleOptions = (includeUnKnown?: boolean): KeyValuePair[] => {
        return this.roleOptions === undefined
            ? []
            : this.roleOptions.sort((a: KeyValuePair, b: KeyValuePair) => {
                  return sortByString(a.key, b.key);
              });
    };

    @computed get getRoleListFormatted(): string {
        let roles = this.roleStore.getRoles.filter((r) => this.model.roleIds.findIndex((ur) => ur === r.id) !== -1);
        return roles ? roles.map((r) => r.displayName).join(", ") : "";
    }

    private constructor() {
        super(new User(""));
        this.setDecorators(LoginModel);
    }

    public setNewUser = () => {
        this.setUser(new User("-1"), true);
    };

    public setUser(user: User, newUser: boolean) {
        this.setValue("id", newUser ? "" : user.id);
        this.setValue("firstName", newUser ? "" : user.firstName);
        this.setValue("lastName", newUser ? "" : user.lastName);
        this.setValue("emailAddress", newUser ? "" : user.emailAddress);
        this.setValue("phoneNumber", newUser ? "" : user.phoneNumber);
        this.setValue("password", newUser ? "" : user.password);
        this.setValue("createdAt", newUser ? "" : user.createdAt);
        this.setValue("roleIds", newUser ? [] : user.roleIds);
        this.setValue("roles", newUser ? [] : user.roles);
        this.setValue("roleDisplayName", "");
        this.setValue("lastLoginDate", newUser ? "" : user.lastLoginDate);
        this.setValue("publicId", newUser ? "" : user.publicId);
        this.setValue("concurrencyStamp", newUser ? "" : user.concurrencyStamp);
        this.setValue("userName", newUser ? "" : user.userName);
        this.setValue("updatedAt", newUser ? "" : user.updatedAt);
    }

    @action
    public showAddEditModal(show: boolean) {
        this.showAEModal = show;
    }

    @computed
    public get getShowAddEditModal() {
        return this.showAEModal;
    }

    @observable
    public userIdToDelete: string | null = null;

    @action
    public setUserIdToDelete = (val: string | null) => {
        this.userIdToDelete = val;
    };

    @computed
    public get getUserToDeleteName(): string {
        const userToDelete = this.getUserForList.find((v) => v.id === this.userIdToDelete);
        return userToDelete ? userToDelete.getName : "unknown";
    }

    public deleteUser = async (userId: string): Promise<void> => {
        const variationToDelete = this.getUserForList.find((u) => u.id === userId);

        if (variationToDelete) {
            const request: GenericId = {
                id: userId,
            };
            let apiResult = await this.Post<any>(AppUrls.Server.Admin.User.Delete, request);
            if (apiResult) {
                if (apiResult.wasSuccessful) {
                    this.loadUsersAsync();
                } else {
                    console.log(apiResult.errors);
                }
            }
        }
    };

    public get(fieldName: any): any {
        return this.getValue(fieldName);
    }

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
    }

    // @action
    // public setRoleDisplayName(roleId: string) {
    //     let role = this.roleStore.getRoles.find((role: Role) => role.id === roleId);
    //     if (role) {
    //         this.setValue("roleDisplayName", role?.displayName || "");
    //     } else {
    //         this.setValue("roleDisplayName", "");
    //     }
    // }

    public async isFieldValid(fieldName: keyof FieldType<User>, value: any): Promise<boolean> {
        const { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadUsersAsync(): Promise<ApiResult<UserListItem[]>> {
        const request: GenericIncludeDeleted = {
            includeDeleted: true,
        };

        let apiResult = await this.Post<UserListItem[]>(AppUrls.Server.Admin.User.GetAll, request);

        if (apiResult.wasSuccessful) {
            this.userStore.setUsersFromList(apiResult.payload);
            MobX.runInAction(() => (this.userCount = this.userStore.getUserCount));
        }
        return apiResult;
    }

    public apiGetAllAndRelated = async (): Promise<void> => {
        const request: GenericIncludeDeleted = {
            includeDeleted: false,
        };
        this.setValue("roleDisplayName", "");
        const apiResult = await this.Post<ProjectListItemsAndRelatedDTO>(AppUrls.Server.Projects.GetAllAndRelated, request);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.populateListItemViewModels(apiResult.payload);
            } else {
                console.log(apiResult.errors);
            }
            //this.setHasLoaded(true);
        }
    };

    @action
    private populateListItemViewModels = (dto: ProjectListItemsAndRelatedDTO) => {
        const projectListModels: ProjectListModel[] = [];

        for (const projectListItem of dto.projectListItems) {
            const itemModel = new ProjectListModel(projectListItem);

            projectListModels.push(itemModel);
        }

        this.projectListModels.replace(projectListModels);

        this.setProjects(projectListModels);

        this.setRoles();
    };

    @action
    public async loadUserAsync(userid: any): Promise<ApiResult<UserDTO>> {
        const request: GenericId = {
            id: userid,
        };

        let apiResult = await this.Post<UserDTO>(AppUrls.Server.Admin.User.GetDetails, request);

        if (apiResult.wasSuccessful) {
            this.model.fromDto(apiResult.payload);
            MobX.runInAction(() => (this.userCount = this.userStore.getUserCount));
            await this.apiGetAllAndRelated();
            //this.setRoleDisplayName(this.model.roles[0].id);
        }
        return apiResult;
    }

    public async resetFailedLoginAttempts(): Promise<void> {
        MobX.runInAction(() => (this.IsLoading = true));
        const apiResult = await this.Post<any>(AppUrls.Server.Admin.ResetFailedLoginAttemptsCount, {
            id: this.getValue("id"),
        });
        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => (this.resetLoginAttemptsError = ""));
            await this.loadUserAsync(this.model.id);
            //            await this.apiGetAllAndRelated();
        } else {
            MobX.runInAction(() => {
                this.IsErrored = true;
                this.resetLoginAttemptsError = "Unknown Error resetting Failed Login Attempts Count";
            });
        }
        MobX.runInAction(() => (this.IsLoading = false));
    }

    @computed get getUsers(): User[] {
        let users = this.userStore.getUsers.slice();
        users.sort((a: UserDTO, b: UserDTO) => {
            if (this.ascOrder) return sortByString(a.firstName, b.firstName);
            else return sortByString(b.firstName, a.firstName);
        });
        return users;
    }

    @computed get getUserForList(): User[] {
        let retVal: User[] = [];
        if (isNullOrUndefined(this.userStore) == false) {
            // remove deleted users
            retVal = this.userStore.getUsers.filter((a) => a.isDeleted === false).slice();
            retVal.sort((a: UserDTO, b: UserDTO) => {
                if (this.ascOrder) return sortByString(a.firstName, b.firstName);
                else return sortByString(b.firstName, a.firstName);
            });
        }
        return retVal;
    }

    public getUser = (id: string) => {
        if (isNullOrUndefined(id) === false) {
            return this.userStore.getUsers.find((u) => u.id === id);
        } else {
            return new User("");
        }
    };

    @computed get getUserCount(): number {
        return this.userCount;
    }

    @computed get getIsLoadingData(): boolean {
        return this.userStore.getIsLoadingData;
    }

    @action
    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    @computed get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    @action
    public doSubmit = async (e?: any, redirect: boolean = true) => {
        e?.preventDefault();
        if ((await this.isModelValid()) && this.additionalChecks()) {
            //Do stuff here
            const apiResult = await this.saveAsync();
            if (apiResult.wasSuccessful) {
                if (redirect) {
                    this.history.push(AppUrls.Client.Admin.User.List);
                }
                return apiResult;
            }
        } else {
            runInAction(() => {
                this.errorMessage = "Please fix the highighted errors";
                this.IsLoading = false;
            });
        }
        return undefined;
    };

    public additionalChecks = (): boolean => {
        let retVal: boolean = true;

        // TODO CMR Check for things like Email already used.

        return retVal;
    };

    @action
    public async saveAsync(): Promise<ApiResult<UserListItem>> {
        const request: UserDTO = this.model.toDto();

        const apiResult = await this.Post<UserListItem>(AppUrls.Server.Admin.User.Upsert, request);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.userStore.setUsersFromList([apiResult.payload]);
                StoresInstance.Domain.UserStore.addEditClient(apiResult.payload);
            });
        } else {
            this.errorMessage = "Failed to save the user. The user might have changed since you opened it.  Reload and try again.";
        }

        return apiResult;
    }

    public isEmailInUse = async (id: string, email: string): Promise<boolean> => {
        const request: IsEmailInUseRequest = {
            id: id,
            email: email,
        };

        const apiResult = await this.Post<boolean>(AppUrls.Server.Admin.User.IsEmailInUse, request);

        if (apiResult.wasSuccessful === true && apiResult.payload === true) {
            MobX.runInAction(() => {
                this.IsErrored = true;
                this.setError("emailAddress", "The email address is already in use.");
                this.setValid("emailAddress", false);
            });
        }

        return apiResult.payload;
    };
}
