export const uppercaseWords = (text: string): string => {
    return text.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
    });
};

export const toAcronym = (name: string) => {
    if (!name) return "";

    const letters = name.match(/\b(\w)/g) || [];
    const acronym = letters.join("");

    return acronym;
};

export const trimChar = (text: string, char: string) => {
    while (text.charAt(0) == char) {
        text = text.substring(1);
    }

    while (text.charAt(text.length - 1) == char) {
        text = text.substring(0, text.length - 1);
    }

    return text;
};

/* export const newLinesToBreak = (text: string, splitRegex = /(?:\r\n|\r|\n)/g) => {
    return text
        .split(splitRegex)
        .map(line => <span>{line}<br /></span>);
}; */
