import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AppUrls } from "AppUrls";
import { ClassNameMap } from "@material-ui/styles";
import { BreadcrumbViewModel } from "../BreadcrumbViewModel";

export const adminUserMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">): any => {
    return (
        <>
            <Link component={RouterLink} to={AppUrls.Client.Admin.User.List}>
                <div className={classes.main}>
                    Users
                    <span className="triangle"></span>
                </div>
            </Link>

            {/* <Link component={RouterLink} to={AppUrls.Client.Admin.Client.List}>
                <div className={classes.prev}>Clients</div>
            </Link> */}
        </>
    );
};

export const adminUserMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    plusSign: any,
): any => {
    return (
        <div className={classes.secondary}>
            <div onClick={viewModel.onAddUser}>
                <img src={plusSign} className="add-image" alt="Add new user" />
                <span className="add-text">Add new user</span>
            </div>
        </div>
    );
};

export const adminUserDetailMatchSection1 = (classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">, arrowSign: any): any => {
    return (
        <>
            <Link component={RouterLink} to={AppUrls.Client.Admin.Root}>
                <div className={classes.prev}>Users</div>
            </Link>

            <div className={classes.main}>
                <div className="secondary-text">User Detail</div>
                <span className="triangle"></span>
            </div>

            {/* <Link component={RouterLink} to={AppUrls.Client.Admin.Client.List}>
                <div className={classes.prev}>Clients</div>
            </Link> */}
        </>
    );
};

export const adminUserDetailMatchSection2 = (
    viewModel: BreadcrumbViewModel,
    classes: ClassNameMap<"root" | "common" | "prev" | "heading" | "main" | "secondary" | "lists" | "nested">,
    addEditImg: any,
): any => {
    return (
        <div className={classes.secondary}>
            <div onClick={viewModel.onEditUser}>
                <span className="add-text">Edit user</span>
            </div>
        </div>
    );
};
