// Libs
import * as MobX from "mobx";

// App
import { EditableCopy as EditableCopyDTO } from "../EditableCopy";

export class EditableCopy {
    @MobX.observable public id: string = "";
    @MobX.observable public routeId: string = "";
    @MobX.observable public placeholderNumber: number = 0;
    @MobX.observable public copyValue: string = "";
    @MobX.observable public createdDate: string = "";
    @MobX.observable public updatedDate: string = "";
    @MobX.observable public updatedBy: string = "";

    constructor(id: string) {
        this.id = id;
    }

    @MobX.action
    public fromDto = (model: EditableCopyDTO) => {
        this.id = model.id || "";
        this.routeId = model.routeId || "";
        this.placeholderNumber = model.placeholderNumber || 0;
        this.copyValue = model.copyValue || "";
        this.createdDate = model.createdDate || "";
        this.updatedDate = model.updatedDate || "";
        this.updatedBy = model.updatedBy || "";
    };
}
