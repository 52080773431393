import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "@shoothill/core";
import { AllProjectsReportViewModel, ReportType } from "./AllProjectsReportViewModel";
import { DarwinPage, DarwinPageContent, DarwinPageTitle } from "Globals/Styles/AppStyling";
import { ProjectsAllReportView } from "./AllReport/ProjectsAllReportView";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { ReportControlContainer } from "../Report.styles";
import { AllProjectCVRReportView } from "./CVR/AllProjectCVRReportView";
import { AllProjectCostReportView } from "./Cost/AllProjectCostReportView";
import { AllProjectInvoiceReportView } from "./Invoice/AllProjectInvoiceReportView";
import { AllProjectApprovedInvoicesReportView } from "./ApprovedInvoice/AllProjectApprovedInvoicesReportView";

export const AllProjectsReportView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new AllProjectsReportViewModel());

    useEffect(() => {
        viewModel.loadRelated();
        return () => {};
    }, []);

    const renderReport = () => {
        const reportType: string = viewModel.reportTypeOption ? viewModel.reportTypeOption.id : "";

        switch (reportType) {
            case ReportType.All:
                return <ProjectsAllReportView />;
            case ReportType.CVR:
                return <AllProjectCVRReportView />;
            case ReportType.Cost:
                return <AllProjectCostReportView />;
            case ReportType.Invoice:
                return <AllProjectInvoiceReportView getProjectStatusOptionsOptions={viewModel.getProjectStatusOptions} />;
            case ReportType.ApprovedInvoices:
                return <AllProjectApprovedInvoicesReportView />;
            default:
                return <>Please select a report</>;
        }
    };

    const renderPage = () => {
        return (
            <DarwinPage>
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        All projects
                    </Typography>
                </DarwinPageTitle>
                <DarwinPageContent>
                    <ReportControlContainer>
                        <DarwinSelect
                            displayName="Report type:"
                            execute={(val: any) => viewModel.setReportType(val.id)}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName || ""}
                            onBlur={() => viewModel.isFieldValid("reportType")}
                            options={viewModel.reportTypeOptions}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("reportType")}
                            value={viewModel.reportTypeOption}
                            canExecute={!viewModel.IsLoading}
                        />
                    </ReportControlContainer>
                    {renderReport()}
                </DarwinPageContent>
            </DarwinPage>
        );
    };

    return useObserver(() => renderPage());
};
