import { Cell, Grid } from "@shoothill/core";
import { observer } from "mobx-react-lite";

import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { SolidDivider } from "../Dividers";
import { PurchaseOrderModel } from "../../PurchaseOrderModel";
import { PurchaseOrderViewModel } from "../../PurchaseOrderViewModel";
import { DarwinMoneyInput } from "Globals/Styles/Control/DarwinInput";
import { Checkbox, FormControlLabel, InputAdornment } from "@material-ui/core";
import { ChangeEvent } from "react";

interface IProps {
    viewModel: PurchaseOrderViewModel;
}

export const DeliverySectionView: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";
    const viewModel = props.viewModel;

    const setIsCollection = (event: ChangeEvent<HTMLInputElement>, val: boolean) => {
        viewModel.setValue("isCollection", val);

        viewModel.setValue("deliveryAddressId", PurchaseOrderModel.DEFAULT_DELIVERYADDRESSID);
        viewModel.setValue("deliveryContactName", PurchaseOrderModel.DEFAULT_DELIVERYCONTACTNAME);
        viewModel.setValue("deliveryContactMobile", PurchaseOrderModel.DEFAULT_DELIVERYCONTACTMOBILE);
    };

    // #endregion Code Behind

    return (
        <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
            <Cell ts={8} ds={8}>
                <FormControlLabel
                    control={<Checkbox checked={viewModel.model.isCollection} onChange={setIsCollection} />}
                    label={"Collection?"}
                    name={`isCollection-checkbox`}
                    disabled={viewModel.isFormDisabled || viewModel.isExistingRequest}
                />
            </Cell>
            {!viewModel.model.isCollection && (
                <>
                    {/* Group of 8 columns */}
                    <Cell ts={3} ds={3}>
                        <DarwinSelect
                            displayName="Delivery address:*"
                            execute={viewModel.setAddress}
                            fullWidth={true}
                            getOptionLabel={(option: any) => option.displayName}
                            onBlur={() => viewModel.isFieldValid("deliveryAddressId")}
                            options={viewModel.projectAddresses}
                            placeholder="Please select"
                            validationMessage={viewModel.getError("deliveryAddressId")}
                            value={viewModel.address}
                            canExecute={viewModel.canExecuteAmendingField && viewModel.canEditDeliveryAddress}
                        />
                    </Cell>

                    <Cell ts={2} ds={2} style={{ display: viewModel.isFormDisabled ? "flex" : "block", alignItems: viewModel.isFormDisabled ? "center" : "initial" }}>
                        <DarwinInputWrapper<PurchaseOrderModel>
                            type="text"
                            label="Delivery contact name:*"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="deliveryContactName"
                            shrink={true}
                            maxLength={64}
                            editMode={viewModel.canExecuteAmendingField && viewModel.canEditDeliveryAddress}
                        />
                    </Cell>

                    <Cell ts={2} ds={2} style={{ display: viewModel.isFormDisabled ? "flex" : "block", alignItems: viewModel.isFormDisabled ? "center" : "initial" }}>
                        <DarwinInputWrapper<PurchaseOrderModel>
                            type="text"
                            label="Delivery contact mobile:*"
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="deliveryContactMobile"
                            shrink={true}
                            maxLength={64}
                            editMode={viewModel.canExecuteAmendingField && viewModel.canEditDeliveryAddress}
                        />
                    </Cell>

                    {viewModel.model.isStock && (
                        <Cell ts={1} ds={1} style={{ display: viewModel.isFormDisabled ? "flex" : "block", alignItems: viewModel.isFormDisabled ? "center" : "initial" }}>
                            <DarwinMoneyInput<PurchaseOrderModel>
                                type="number"
                                label="Delivery costs:"
                                placeholder=""
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                fieldName="deliveryCosts"
                                shrink={true}
                                maxLength={11}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                editMode={viewModel.canExecuteAmendingField && viewModel.canEditDeliveryAddress}
                            />
                        </Cell>
                    )}

                    {!viewModel.model.isStock && <Cell ts={1} ds={1}></Cell>}
                </>
            )}

            {/* Group of 8 columns */}
            <Cell ts={8} ds={8}>
                <SolidDivider gutterBottom />
            </Cell>
        </Grid>
    );
});
