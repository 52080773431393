import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";
export class RejectedVarListModel extends ModelBase<RejectedVarListModel, RejectedVarListModelDTO> {
    @MobX.observable
    public id: string = "";

    @MobX.observable
    public variationId: string = "";

    @MobX.observable
    public variationNumber: number | null = 0;

    @MobX.observable
    public formattedVariationNumber: string = "";

    @MobX.observable
    public revision: string = "";

    @MobX.observable
    public description: string = "";

    @MobX.observable
    public variationStatusId: string = "";

    @MobX.observable
    public variationStatusName: string = "";

    @MobX.observable
    public lastUpdatedDate: Date | null = null;

    @MobX.observable
    public filterStartDateINV: Date | null = null;

    @MobX.observable
    public filterEndDateINV: Date | null = null;

    @MobX.computed get getFilterStartDateINV(): string | null {
        return this.filterStartDateINV ? this.filterStartDateINV.toISOString() : null;
    }

    @MobX.computed get getFilterEndDateINV(): string | null {
        return this.filterEndDateINV ? this.filterEndDateINV.toISOString() : null;
    }

    @MobX.action validateFilters = () => {
        let start: moment.Moment | null = null;
        let end: moment.Moment | null = null;

        if (this.filterStartDateINV !== null && this.filterStartDateINV !== undefined) {
            start = moment.utc(this.filterStartDateINV);
        }
        if (this.filterEndDateINV !== null && this.filterEndDateINV !== undefined) {
            end = moment.utc(this.filterEndDateINV);
        }

        if (start !== null && end !== null) {
            if (start < end) {
                return true;
            }
        } else {
            return true;
        }

        return false;
    };

    fromDto(model: RejectedVarListModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: RejectedVarListModel): void {}
}

export type RejectedVarListModelDTO = {
    id: string;
    variationId: string;
    variationNumber: number | null;
    formattedVariationNumber: string;
    revision: string;
    description: string;
    variationStatusId: string;
    variationStatusName: string;
    lastUpdatedDate: Date | null;
};
