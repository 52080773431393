import * as React from "react";
import { ControlLabelContainer } from "./ControlLabel.styles";

interface IProps {
    label: string;
    htmlFor: string;
    disableinputpadding?: boolean;
}

export const ControlLabel: React.FC<IProps> = ({ label, htmlFor, children, disableinputpadding }) => {
    return (
        <ControlLabelContainer variant="body1" component="label" htmlFor={htmlFor} disableinputpadding={disableinputpadding}>
            <span className="control-label">{label}</span>
            {children}
        </ControlLabelContainer>
    );
};
